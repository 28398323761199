/**
 * Application views text value to be shown to the user
 */
const app = {
  // Security page view
  security: {
    // Sign-in view
    'sign-in': {
      'basic-authentication': 'Basic authentication',
      'password-display-name': 'Password',
      'password-help-text': 'Passwords serve as the first layer of authentication, verifying user identity during login.',
      'two-factor': 'Two-factor authentication',
      'otp-display-name': 'Authenticator application',
      'otp-help-text': 'Contains time-based OTP used as a second factor during login.',
      'recovery-authn-codes-display-name': 'Recovery codes',
      'recovery-authn-codes-help-text': `These codes can be used to regain your access in case the authenticator application OTP is not available.
        <br><b>Note:</b> Reconfiguring replaces old codes with new ones, making the old ones unusable.`,
      'passwordless': 'Passwordless',
      'webauthn-passwordless-display-name': 'Passkey',
      'webauthn-passwordless-help-text': `Passkeys in sign-in provide secure, passwordless authentication, improving both security and user experience.
        <br>They also help prevent phishing attacks by eliminating traditional passwords.`,
      icons: {
        'password': 'laptop',
        'otp': 'cellphone',
        'recovery-authn-codes': 'file-document',
        'webauthn-passwordless': 'key-variant',
      },
    }
  }
 };

export default Object.freeze(app);
